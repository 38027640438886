import React, { useState } from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import { MDBCheckbox } from 'mdb-react-ui-kit';
import axios from 'axios';
import logo from '../Assent/Imagenes/logo.png';
import qrImage from '../Assent/Imagenes/qrcerbero.png';

function App() {


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rut: '',  // Agregué el campo "rut"
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Aquí puedes realizar la llamada a la API usando Axios
        try {
            const response = await axios.post('https://demo.edgarleal.es/web/cerbero/registrar.php', formData);
            console.log('API response:', response.data);

            // Mostrar un alert
            alert('¡Registro exitoso!');

            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                rut: '',
            });
        } catch (error) {
            console.error('Error al registrar:', error.message);
            // Mostrar un alert de error si es necesario
            alert('Error al registrar:', error.message);
        }
    };

    return (
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MDBContainer>
                <MDBCard className='my-5 cascading-right' style={{ background: 'hsla(0, 0%, 100%, 0.55)', backdropFilter: 'blur(30px)' }}>
                    <MDBCardBody className='p-5 shadow-5' style={{ margin: '0' }}>
                        <MDBRow className='g-0 align-items-center'>
                            <MDBCol md='6'>
                                <div className='d-flex flex-column align-items-center'>
                                    <img
                                        src={logo}
                                        alt='Logo'
                                        className='mb-0 mt-0'
                                        style={{
                                            width: '200px',
                                            height: '200px',
                                            boxSizing: 'border-box',
                                            padding: '0',
                                            margin: '0',
                                        }}
                                    />
                                    <h2 className="fw-bold mb-5">Regístrate ahora</h2>

                                    <form onSubmit={handleSubmit}>
                                        <MDBRow className='mb-3'>
                                            <MDBCol size='6'>
                                                <MDBInput
                                                    label='Nombre'
                                                    id='form1'
                                                    type='text'
                                                    name='firstName'
                                                    value={formData.firstName}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBCol>

                                            <MDBCol size='6'>
                                                <MDBInput
                                                    label='Apellido'
                                                    id='form2'
                                                    type='text'
                                                    name='lastName'
                                                    value={formData.lastName}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className='mb-3'>
                                            <MDBCol size='12'>
                                                <MDBInput
                                                    label='Correo Electronico'
                                                    id='form3'
                                                    type='email'
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className='mb-3'>
                                            <MDBCol size='12'>
                                                <MDBInput
                                                    label='Rut'
                                                    id='form3'
                                                    name='rut'  // Cambié el nombre del campo a "rut"
                                                    value={formData.rut}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow className='mb-3'>
                                            <MDBCol size='12'>
                                                <MDBInput
                                                    label='Contraseña'
                                                    id='form4'
                                                    type='password'
                                                    name='password'
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBBtn type='submit' className='w-100 mb-4' size='md'>
                                            Registrarse
                                        </MDBBtn>
                                    </form>

                                    <div className="text-center">
                                        <p>o regístrate con:</p>
                                        <MDBBtn tag='a' color='none' className='mx-2' style={{ color: '#1266f1' }}>
                                            <MDBIcon fab icon='facebook-f' size="sm" />
                                        </MDBBtn>

                                        <MDBBtn tag='a' color='none' className='mx-2' style={{ color: '#1266f1' }}>
                                            <MDBIcon fab icon='google' size="sm" />
                                        </MDBBtn>
                                    </div>
                                </div>
                            </MDBCol>

                            {/* Código QR y i */}
                            <MDBCol md='6' className='text-center'>
                                <div className='vertical-line mx-3' style={{ borderLeft: '2px solid #777', height: '80%', position: 'absolute', top: '10%', bottom: '10%' }}></div>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src={qrImage} alt='Código QR para descargar la app' className='img-fluid mb-3' style={{ width: '200px' }} />
                                
                                <p className='font-italic text-muted'>Escanéame para descargar la app</p>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>

            </MDBContainer>
        </div>
    );
}

export default App;
